import { Button, ContainerMaxWidth, Stack } from '@hypersay/ui';
import React from 'react';
import SEO from '../components/seo';
import T from '../components/T';
import Layout from '../layouts/default';

import { withPrefix } from 'gatsby';
import * as styles from './live-subtitles.module.scss';

const Education = () => (
  <Layout>
    <SEO
      title="Live Subtitles - speech to text and translations for live presentations"
      description="Hypersay Live Presentations now will transcribe your speech and translate it automatically to 120 languages."
      shareImage="share-image-captions.jpg"
      keywords={[
        'live subtitles',
        'live transcription',
        'speech recognition',
        'speech to text',
        'natural language processing',
        'live translations',
        'automated translations',
        'captions',
        'closed captions',
        'live presentations',
        'speech to text',
        'interactive presentations',
        'live polling',
        'powerpoint',
      ]}
    />
    <ContainerMaxWidth className={styles.container} maxWidth="1400px">
      <div className={styles.highlightContainer}>
        <Stack gap=".5em" className={`${styles.highlightColumn}`}>
          <img
            src={withPrefix('/images/liveSubtitles/LiveSubtitle.svg')}
            alt="interactions"
          />
          <div className={styles.betaTitle}>
            <div style={{ position: 'relative' }}>
              <T tagName="h2" id="Live Subtitles" />
              {/* <div className={styles.betaTag}>{'private beta'}</div> */}
            </div>
          </div>
          <div className={styles.requestOnly}>
            <T tagName="strong" id="Private beta:" />
            &nbsp;
            <T tagName="span" id="available on request only" />
          </div>
          <T
            tagName="p"
            // tslint:disable-next-line: max-line-length
            id="When you run a live presentation with Hypersay, our platform can now automatically understand and transcribe your words (in 64 languages). Show captions on screen. Your participants can opt to have the text translated in any language they want. Increases the retention and the accessibility of your presentation."
          />
        </Stack>
      </div>

      <div className={styles.highlightContainer}>
        <div className={`${styles.highlight} ${styles.interactions}`}>
          <div className={styles.image}>
            <img
              src={withPrefix('/images/liveSubtitles/speech-bubble.png')}
              alt="interactions"
            />
          </div>
          <div className={`${styles.text}`}>
            <Stack gap=".5em">
              <T tagName="h2" id="Speech recognition" />
              <T
                tagName="p"
                // tslint:disable-next-line: max-line-length
                id="Siri, Alexa, “Hey Google” — computers are getting better and better at understanding our voice. Hypersay is bringing the power of speech recognition for the benefit of your participants."
              />
              <T
                tagName="p"
                // tslint:disable-next-line: max-line-length
                id="We are using Google's services to automatically transcribe subtitles and translations. There are currently 64 languages available."
              />
              <T
                tagName="p"
                // tslint:disable-next-line: max-line-length
                id="“We can’t hear you!”, “What did she say?”, “Oh, I missed that” — these will no longer happen. Now your participants have the transcription both on the large screen and in their connected devices."
              />
            </Stack>
          </div>
        </div>
      </div>

      <div className={styles.highlightContainer}>
        <div className={`${styles.highlight} ${styles.interactions}`}>
          <div className={`${styles.text}`}>
            <Stack gap=".5em">
              <T tagName="h2" id="Live translations" />
              <T
                tagName="p"
                // tslint:disable-next-line: max-line-length
                id="Let’s push things a bit further. Now that we have speech recognition working for your presentation, the participants can opt to change the destination language to something they are more familiar with."
              />
              <T
                tagName="p"
                // tslint:disable-next-line: max-line-length
                id="We use the same trusted Google services to automatically transcribe into 105 languages. We are confident your preferred language is among those."
              />
            </Stack>
          </div>
          <div className={styles.image}>
            <img
              src={withPrefix('images/liveSubtitles/translations-bubble.svg')}
              alt="interactions"
            />
          </div>
        </div>
      </div>

      <div className={styles.highlightContainer}>
        <div className={`${styles.highlight} ${styles.interactions}`}>
          <div className={styles.image}>
            <img
              src={withPrefix('images/liveSubtitles/accuracy-bubble.svg')}
              alt="interactions"
            />
          </div>
          <div className={`${styles.text}`}>
            <Stack gap=".5em">
              <T tagName="h2" id="Accuracy" />
              <T
                tagName="p"
                id="“Lost in translation” is not just the name of a great movie, it is also a reality of a globalised world. Computers do their best to use Ai, rich context and complex algorithms to automatically recognise speech and perform real-time translations. But they will fail, from time to time, so accuracy becomes a problem."
              />
              <T
                tagName="p"
                id="Factors such as background noise, quality of microphone, local accent, internet speed and pace of speech will highly influence the number of mistakes that occur."
              />
              <T
                tagName="p"
                id="However, if the audience does not speak your native language, they will benefit from understanding 80% of what you say. Speak slowly on important points, repeat them if necessary and your message will be understood."
              />
              <T
                tagName="p"
                id="Important tip: use your usual wireless Apple AirPods, Samsung Galaxy Buds, Echo Buds or any other preferred wireless earbuds. They will greatly improve the quality of speech recognition."
              />
            </Stack>
          </div>
        </div>
      </div>

      <div className={styles.highlightContainer}>
        <div className={`${styles.highlightColumn}`}>
          <Stack className={`${styles.contentCenter}`} gap=".5em">
            <T tagName="h2" id="I want to try it now!" />
            <T
              tagName="p"
              id="Live Subtitles and Translations is a feature under development and testing. The availability is 'private beta', i.e. we can enable it for select users. You can request this feature enales in your account by using the customer support channel (Chat) below."
            />
            <div className={styles.tryImage}>
              <img
                src={withPrefix('images/liveSubtitles/where-to-find.jpg')}
                alt="interactions"
              />
            </div>
            <T
              tagName="p"
              id="Select spoken and translation languages and the participants will automatically have this feature activated."
            />
          </Stack>
        </div>
      </div>
      <div className={styles.highlightContainer}>
        <div className={`${styles.highlightColumn}`}>
          <Button
            className={styles.chatBtn}
            // size="xxl"
            rounded="5px"
            colors={['--onyx', '--urobilin']}
            label={<T id="Questions? Chat with us!" />}
            onClick={() =>
              (window.location.href =
                'javascript:$zopim.livechat.window.show();')
            }
          />
        </div>
      </div>
    </ContainerMaxWidth>
  </Layout>
);

export default Education;
